import logo from './logo.svg';
import './App.css';
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import React, { Suspense } from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import ReactGA from "react-ga4";


const Header = React.lazy(() => import('./component/Header'));
const Home = React.lazy(() => import('./component/Home'));
const AboutUs = React.lazy(() => import('./component/AboutUs'));
const Plans = React.lazy(() => import('./component/Plans'));
const Servises = React.lazy(() => import('./component/Servises'));
const Search = React.lazy(() => import('./component/Search'));
const TermCondition = React.lazy(() => import('./component/TermCondition'));
const PrivacyPolicy = React.lazy(() => import('./component/PrivacyPolicy'));
const ConfirmBooking = React.lazy(() => import('./component/ConfirmBooking'));
const ContactUs = React.lazy(() => import('./component/ContactUs'));

const ThankYou = React.lazy(() => import('./component/ThankYou'));
const Login = React.lazy(() => import('./component/Login/Login'));
const Register = React.lazy(() => import('./component/Login/Register'));
const PuneToMumbai = React.lazy(() => import('./component/PuneToMumbai'));
const MumbaiToPune = React.lazy(() => import('./component/MumbaiToPune'));
const PuneToShirdi = React.lazy(() => import('./component/PuneToShirdi'));


const LogOut = React.lazy(() => import('./component/Login/LogOut'));

const History = React.lazy(() => import('./component/User/History'));
const Booking = React.lazy(() => import('./component/Admin/Booking'));
const SearchLog = React.lazy(() => import('./component/Admin/SearchLog'));
const UserProfile = React.lazy(() => import('./component/User/Profile'));
const BookingDetails = React.lazy(() => import('./component/User/BookingDetails'));
const AdminHome = React.lazy(() => import('./component/Admin/AdminHome'));
const Completed = React.lazy(() => import('./component/Admin/Completed'));
const ReadyToGo = React.lazy(() => import('./component/Admin/ReadyToGo'));
const AddCabs = React.lazy(() => import('./component/Admin/AddCabs'));
const AddCitySurge = React.lazy(() => import('./component/Admin/AddCitySurge'));
const SpecialPrices = React.lazy(() => import('./component/Admin/SpecialPrices'));
const AddBookings = React.lazy(() => import('./component/Admin/AddBookings'));

const Drivers = React.lazy(() => import('./component/Admin/Drivers'));
const Cars = React.lazy(() => import('./component/Admin/Cars'));
const Agents = React.lazy(() => import('./component/Admin/Agents'));
const AgentDetails = React.lazy(() => import('./component/Admin/AgentDetails'));
const WaitingForDriver = React.lazy(() => import('./component/Admin/WaitingForDriver'));
const WaitingForAgent = React.lazy(() => import('./component/Admin/WaitingForAgent'));
const Partner = React.lazy(() => import('./component/Partner/Home'));
const BookingList = React.lazy(() => import('./component/Partner/BookingList'));
const PendingBookingList = React.lazy(() => import('./component/Partner/PendingBookingList'));
const CompletedBookingList = React.lazy(() => import('./component/Partner/CompletedBookingList'));
const Profile = React.lazy(() => import('./component/Partner/Profile'));
const AddCar = React.lazy(() => import('./component/Partner/AddCar'));
const AddDriver = React.lazy(() => import('./component/Partner/AddDriver'));
const Driver = React.lazy(() => import('./component/Driver/Home'));
const BookingReport = React.lazy(() => import('./component/Driver/BookingReport'));
const PaymentReport = React.lazy(() => import('./component/Driver/PaymentReport'));
const DriverProfile = React.lazy(() => import('./component/Driver/Profile'));
const TripDetails = React.lazy(() => import('./component/Driver/TripDetails'));
const TripComplete = React.lazy(() => import('./component/Driver/TripComplete'));

function App() {
  ReactGA.initialize("'G-SC4SL30Q0S");
  return (

    <Router>

      <Switch>
        <Route exact path='/Home/:title?'><Suspense fallback={<ClipLoader loading={true} >  </ClipLoader>}><Home /></Suspense></Route>
        <Route exact path='/home/:title?'><Suspense fallback={<ClipLoader loading={true} >  </ClipLoader>}><Home /></Suspense></Route>
        <Route exact path='/admin/Home/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><AdminHome /></Suspense></Route>
        <Route exact path='/admin/newBookings/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><AdminHome /></Suspense></Route>
        <Route exact path='/Plans/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Plans /></Suspense></Route>
        <Route exact path='/Servises/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Servises /></Suspense></Route>
        <Route exact path='/Login/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Login /></Suspense></Route>
        <Route exact path='/Register/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Register /></Suspense></Route>
        <Route exact path='/LogOut/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><LogOut /></Suspense></Route>
        <Route exact path='/PuneToMumbai/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><PuneToMumbai /></Suspense></Route>
        <Route exact path='/MumbaiToPune/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><MumbaiToPune /></Suspense></Route>
        <Route exact path='/PuneToShirdi/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><PuneToShirdi /></Suspense></Route>
        
        <Route exact path='/History/:title?' ><Suspense fallback={<ClipLoader loading={true} size={20} >  </ClipLoader>}><History /></Suspense></Route>
        <Route exact path='/Profile/:title?' ><Suspense fallback={<ClipLoader loading={true} size={20} >  </ClipLoader>}><UserProfile /></Suspense></Route>
        <Route exact path='/termCondition/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><TermCondition /></Suspense></Route>
        <Route exact path='/privacyPolicy/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><PrivacyPolicy /></Suspense></Route>
        <Route exact path='/AboutUs/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><AboutUs /></Suspense></Route>
        <Route exact path='/ContactUs/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><ContactUs /></Suspense></Route>



        <Route exact path='/admin/Booking/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Booking /></Suspense></Route>
        <Route exact path='/admin/SearchLog/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><SearchLog /></Suspense></Route>
        <Route exact path='/admin/completed/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Completed /></Suspense></Route>
        <Route exact path='/admin/ready/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><ReadyToGo /></Suspense></Route>
        <Route exact path='/admin/driverWaiting/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><WaitingForDriver /></Suspense></Route>
        <Route exact path='/admin/agentWaiting/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><WaitingForAgent /></Suspense></Route>
        <Route exact path='/admin/AddCar/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><AddCar /></Suspense></Route>
        <Route exact path='/admin/AddDriver/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><AddDriver /></Suspense></Route>
        <Route exact path='/admin/addSurge/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><AddCitySurge /></Suspense></Route>
        <Route exact path='/admin/addCabs/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><AddCabs /></Suspense></Route>
        <Route exact path='/admin/AllCars/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Cars /></Suspense></Route>
        <Route exact path='/admin/AllDrivers/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Drivers /></Suspense></Route>
        <Route exact path='/admin/AllAgents/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Agents /></Suspense></Route>
        <Route exact path='/admin/ViewAgent/:agentId/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><AgentDetails /></Suspense></Route>

        <Route exact path='/admin/SpecialPrices/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><SpecialPrices /></Suspense></Route>
        <Route exact path='/admin/AddBookings/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><AddBookings /></Suspense></Route>


        <Route exact path='/BookingDetails/:bookingId/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><BookingDetails /></Suspense></Route>
        <Route exact path='/ConfirmBooking/:data/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><ConfirmBooking /></Suspense></Route>
        <Route exact path='/ThankYou/:orderId/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><ThankYou /></Suspense></Route>
        <Route exact path='/Search2/:pickup/:destination/:pickdate/:returnDate/:pickupLocation/:destinationLocation/:mobileNo' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Search /></Suspense></Route>
        <Route exact path='/Search/:data/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Search /></Suspense></Route>

        <Route exact path='/agent/Login/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Partner /></Suspense></Route>
        <Route exact path='/agent/Home/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><BookingList /></Suspense></Route>
        <Route exact path='/agent/myPendingBookings/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><PendingBookingList /></Suspense></Route>
        <Route exact path='/agent/myCompletedBookings/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><CompletedBookingList /></Suspense></Route>
        <Route exact path='/agent/Profile/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Profile /></Suspense></Route>
        <Route exact path='/agent/AddCar/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><AddCar /></Suspense></Route>
        <Route exact path='/agent/AddDriver/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><AddDriver /></Suspense></Route>
        <Route exact path='/driver/home/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Driver /></Suspense></Route>
        <Route exact path='/driver/booking-report/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><BookingReport /></Suspense></Route>
        <Route exact path='/driver/payment-report/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><PaymentReport /></Suspense></Route>
        <Route exact path='/driver/profile/:title?' ><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><DriverProfile /></Suspense></Route>
        <Route exact path='/driver/trip-details/:bookingId/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><TripDetails /></Suspense></Route>
        <Route exact path='/driver/trip-complete/:bookingId/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><TripComplete /></Suspense></Route>

        <Route exact path='/:title?'><Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Home /></Suspense></Route>

        <Suspense fallback={<ClipLoader style={{ borderColor: 'red' }} loading={true} size={20} >  </ClipLoader>}><Header /></Suspense>
      </Switch>
    </Router>

  );
}

export default App;
